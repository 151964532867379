import { LoadableAfterPaint } from '@confluence/loadable';

export const ByLineForgeApp = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ByLineForgeApp" */ './ByLineForgeApp'))
			.ByLineForgeApp,
});

export const ContentActionForgeApp = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentActionForgeApp" */ './ContentActionForgeApp'
			)
		).ContentActionForgeApp,
});

export const ContextMenuForgeApp = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContextMenuForgeApp" */ './ContextMenuForgeApp'))
			.ContextMenuForgeApp,
});

export const GlobalPageForgeApp = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GlobalPageForgeApp" */ './GlobalPageForgeApp'))
			.GlobalPageForgeApp,
});

export const GlobalSettingsForgeApp = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalSettingsForgeApp" */ './GlobalSettingsForgeApp'
			)
		).GlobalSettingsForgeApp,
});

export const HomepageFeedForgeApp = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-HomepageFeedForgeApp" */ './HomepageFeedForgeApp'))
			.HomepageFeedForgeApp,
});

export const SpaceSettingsForgeApp = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceSettingsForgeApp" */ './SpaceSettingsForgeApp'
			)
		).SpaceSettingsForgeApp,
});

export const SpacePageForgeApp = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpacePageForgeApp" */ './SpacePageForgeApp'))
			.SpacePageForgeApp,
});

export const CustomContentForgeApp = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CustomContentForgeApp" */ './CustomContentForgeApp'
			)
		).CustomContentForgeApp,
});
