import React, { Fragment, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	VIEW_PAGE_CONTENT_TOOLS_FORGE_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import {
	ContentActionForgeApp,
	getAppId,
	lazyForgeUIExtensionsWithLoader,
	getModuleKey,
	getContentType,
	FORGE_MODULE_CONTENT_ACTION,
} from '@confluence/forge-ui';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { usePageState } from '@confluence/page-context';
import { getSpaceType, useSpaceIdentifiers } from '@confluence/space-utils';
import { Attribution } from '@confluence/error-boundary';
import { isErrorMarkedAsHandled } from '@confluence/graphql-error-processor';
import { getMonitoringClient } from '@confluence/monitoring';

import { ForgeContentToolsItem } from './ForgeContentToolsItem';
import { ContentToolsButton } from './ContentToolsButton';

const EXPERIENCE_NAME = VIEW_PAGE_CONTENT_TOOLS_FORGE_EXPERIENCE;

/**
 * Calls back children with the forge extension menu items. Also contains the logic of how to load selected
 * forge extension.
 * @param contentId Current contentId
 * @param spaceKey Current spaceKey
 * @param children Function that accepts forge menu items.
 * @param onLoadComplete Triggered when the forge extension is loaded.
 * @param isDropdownMenuOpen Boolean that indicates open/close status of containing dropdown menu.
 * @param dialogs A DialogsStateContainer which is needed to show modals in live pages (optional argument and only passed in when page is a live one).
 */
export function ForgeContentTools({
	contentId,
	spaceKey,
	children,
	onLoadComplete,
	isDropdownMenuOpen,
	dialogs,
}) {
	const experienceTracker = useContext(ExperienceTrackerContext);
	// Forge menu items are behaving a little different than other menu items. They show a loading spinner if one of them
	// is clicked. There are a few different cases that needs to be handled while a forge extension is being loaded:
	// 1. Show a loading spinner while an extension id being loaded.
	//    - Stop showing the loading spinner once the extension is loaded.
	//    - Close the dropdown menu once the extension is loaded.
	// 2. If dropdown menu is closed while an extension is being loaded then stop loading the extension.
	// If there's a selected extension then `loadExtension` state changes like this:
	// +----------+----------------------+------------------+
	// |isLoading |   isDropdownMenuOpen |    loadExtension |
	// +----------------------------------------------------+
	// |          |                      |                  |
	// | false    |   false              |    true          |  --> keep showing the loaded extension
	// |          |                      |                  |
	// | false    |   true               |    true          |  --> keep showing the loaded extension
	// |          |                      |                  |
	// | true     |   false              |    false         |  --> stop loading the extension
	// |          |                      |                  |
	// | true     |   true               |    true          |  --> keep loading the extension
	// +----------+----------------------+------------------+
	const [extension, showExtension] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const loadExtension = extension && !(isLoading && !isDropdownMenuOpen);

	useEffect(() => {
		if (!loadExtension) {
			experienceTracker.abort({
				name: EXPERIENCE_NAME,
				reason: 'Canceled by user',
			});
			setLoading(false);
			showExtension(null);
		}
	}, [loadExtension, experienceTracker]);

	const onLoadCompleteCB = useCallback(() => {
		onLoadComplete();
		setLoading(false);
	}, [onLoadComplete, setLoading]);

	const onCloseCB = useCallback(() => {
		showExtension(null);
	}, [showExtension]);

	const onForgeExtensionClick = useCallback(
		(ext: any) => {
			experienceTracker.start({
				name: EXPERIENCE_NAME,
				id: ext.id,
				timeout: 10000,
			});
			showExtension(ext);
			setLoading(true);
		},
		[showExtension, setLoading, experienceTracker],
	);

	// shows up when preloaded in SSR. Shows up again when SPA takes over bf all queries are loaded.
	const LazyLoader = useMemo(
		() =>
			lazyForgeUIExtensionsWithLoader(() => (
				<ContentToolsButton
					isLoading
					isDisabled={!(window.__SSR_RENDERED__ || process.env.REACT_SSR)}
				/>
			)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<Fragment>
			{process.env.REACT_SSR && (
				<SSRActionLoadingSpinner
					spinnerId="more-action-loading-spinner"
					actionType="moreActionButton"
				/>
			)}
			{loadExtension && (
				<ForgeUIExtensionLoader
					extension={extension}
					contentId={contentId}
					spaceKey={spaceKey}
					onLoadComplete={onLoadCompleteCB}
					onClose={onCloseCB}
					dialogs={dialogs}
				/>
			)}
			<LazyLoader
				moduleType={FORGE_MODULE_CONTENT_ACTION}
				render={(extensions, loading, error) => {
					if (loading) return children([]);

					if (error) {
						if (!isErrorMarkedAsHandled(error)) {
							getMonitoringClient().submitError(error, {
								attribution: Attribution.ECOSYSTEM,
							});
						}
						return children([]);
					}

					const result = extensions.map((ext) => (
						<ForgeContentToolsItem
							key={ext.id}
							extension={ext}
							onClick={onForgeExtensionClick}
							isLoading={extension === ext}
						/>
					));

					return children(result);
				}}
			/>
		</Fragment>
	);
}

ForgeContentTools.propTypes = {
	contentId: PropTypes.string.isRequired,
	spaceKey: PropTypes.string.isRequired,
	children: PropTypes.func.isRequired,
	onLoadComplete: PropTypes.func.isRequired,
	isDropdownMenuOpen: PropTypes.bool.isRequired,
	dialogs: PropTypes.object,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InvisibleDiv = styled.div({
	display: 'none',
});

/**
 * LazyForgeUIExtension has its own loading spinner, however, we don't want to show it anywhere. We want to show a
 * loading spinner inside the menu item while loading the extension, and then close the menu once the loading is complete.
 * To accomplish that, we're rendering LazyForgeUIExtension inside an invisible div.
 * @param forgeExtension To be loaded.
 * @param contentId Passed down to ForgeUIExtension both contentId and spaceKey becomes available in the forge app with
 * `useProductContext` hook. For reference https://developer.atlassian.com/platform/forge/ui-hooks-reference/.
 * @param spaceKey Passed down to ForgeUIExtension.
 * @param onLoadComplete Triggered when the forge extension is loaded.
 * @param onClose Triggered when the rendered ModalDialog is closed. (Currently ContentAction forge component can only contain a ModalDialog)
 * @returns LazyForgeUIExtension inside an InvisibleDiv.
 * @constructor
 */
function ForgeUIExtensionLoader({
	extension,
	contentId,
	spaceKey: spaceAlias,
	onLoadComplete,
	onClose,
	dialogs,
}) {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [{ routeName }] = usePageState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { activationId } = useSessionData();
	const { spaceId, spaceKey } = useSpaceIdentifiers();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	const onInitialRender = useCallback(() => {
		experienceTracker.succeed({
			name: EXPERIENCE_NAME,
		});
		onLoadComplete();

		const analyticEvent = createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'invoked',
				actionSubject: 'forgeExtension',
				containerType: 'space',
				containerId: spaceId,
				objectType: getContentType(routeName),
				objectId: contentId,
				source: FORGE_MODULE_CONTENT_ACTION,
				attributes: {
					activationId,
					appId: getAppId(extension),
					moduleType: FORGE_MODULE_CONTENT_ACTION,
					moduleKey: getModuleKey(extension),
					spaceType: getSpaceType(spaceAlias),
					spaceId,
				},
			},
		});
		analyticEvent.fire();
	}, [
		onLoadComplete,
		experienceTracker,
		activationId,
		createAnalyticsEvent,
		contentId,
		spaceAlias,
		spaceId,
		extension,
		routeName,
	]);

	if (isSpaceAliasFFEnabled && !spaceKey) {
		return null;
	}

	return (
		<InvisibleDiv>
			<ContentActionForgeApp
				// this key is required to enable user switch in between different forge apps during loading of an extension
				key={extension.id}
				app={extension}
				extensionData={{
					type: FORGE_MODULE_CONTENT_ACTION,
					// @ts-ignore FIXME `type` is declared by `extensionData` to be required for `content`
					content: {
						id: contentId,
					},
					space: {
						// The !!spaceKey check is redundant due to the preceeding return, but
						// it is kept to satisfy the type system.
						key: isSpaceAliasFFEnabled && !!spaceKey ? spaceKey : spaceAlias,
						id: spaceId,
					},
				}}
				onInitialRender={onInitialRender}
				onTearDown={onClose}
				dialogs={dialogs}
			/>
		</InvisibleDiv>
	);
}

ForgeUIExtensionLoader.propTypes = {
	extension: PropTypes.object.isRequired,
	contentId: PropTypes.string.isRequired,
	spaceKey: PropTypes.string.isRequired,
	onLoadComplete: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};
