import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import PropTypes from 'prop-types';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner/spinner';
import { N50 } from '@atlaskit/theme/colors';

import { extensionTitle } from '@confluence/forge-ui';

import { ContentToolsItem } from './ContentToolsItem';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isLoading: boolean }>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.isLoading ? token('color.text.subtlest', N50) : 'unset'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenterSpinner = styled.div({
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.negative.100', '-8px'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

/**
 * This component is a wrapper around ContentToolsItem. It shows a loading spinner while the forge extension is
 * being loaded.
 * @param extension Forge extension that this menu item created for
 * @param onClick Handler
 * @param isLoading Indicates that extension is currently being loaded
 */
export function ForgeContentToolsItem({ extension, onClick, isLoading }) {
	const onClickCB = useCallback(
		(e: any) => {
			e.stopPropagation();
			onClick(extension);
		},
		[extension, onClick],
	);

	return (
		<ContentToolsItem
			id={extension.id}
			isDisabled={isLoading}
			label={
				<Container isLoading={isLoading}>
					{extensionTitle(extension)}

					{isLoading && (
						<CenterSpinner data-test-id="forge-ui-extension-loading-spinner">
							<Spinner size="small" />
						</CenterSpinner>
					)}
				</Container>
			}
			onClick={onClickCB}
		/>
	);
}

ForgeContentToolsItem.propTypes = {
	extension: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};
